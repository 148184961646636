.home {
    height: 100vh;
    width: 100%;
}

.customBackground {
    background-color: rgba(245, 245, 245, 1);
    padding: 0 48px 16px;
    .ant-tabs-nav{
        background-color: rgba(245, 245, 245, 1);
    }
}

.homeContent {
    padding: 16px 48px
}

.ant-table-title {
    background-color: #fafafa;
}

.ant-table-footer {
    background-color: #ffffff !important;
    border: 0 !important;
   
}

.ant-collapse-item {
    width: '100%' !important
}

.ant-divider-dashed {
    border-color: #d9d9d9 !important;
}

.databaseOutlined-purple {
    background-color: #722ED1;
    margin-right: 5px
}

.databaseOutlined-green {
    background-color: #13C2C2;
    margin-right: 5px
}

.ant-card {
    box-shadow:none !important;
}
.ant-dropdown-menu .ant-card-body {
    padding: 0 15px;
}

.ant-dropdown-menu .ant-list-items {
    max-height: 500px;
    overflow-y: auto;
}

.ant-select-arrow-loading {
    color: #1677ff !important;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu{
    margin-top: -2px;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.reconciliationCollapse.ant-collapse {
    border: 0px solid  #ffffff;
}
.ant-table-row-selected td{
    background-color : #FFFFFF !important;
}

.highlighted-row >.ant-table-cell {
    background-color : #E6F4FF !important;
}

.card-body-padd10 .ant-card-body {
    padding: 10px;
    min-height: 227px;
}

.customTableClass .ant-table{
    margin-block: 0 !important;
    margin-inline: 0 !important;
}

.non-clickable-row {
    cursor: no-drop;
}

.disablePagination {
    display: none;
    // pointer-events: none;
    // color: rgba(0, 0, 0, 0.25);  /* Make it look like it's disabled */
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: text;
    height: 31px;
}

.editable-cell-value-wrap-textarea {
    padding: 5px 12px;
    cursor: text;
    max-height: 54px;
    min-height: 31px;
    overflow-y: auto;
}

.editable-row .editable-cell-value-wrap, .editable-row .editable-cell-value-wrap-textarea {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.negativeTextClass input.ant-input{
    color:#ff4d4f !important;
}

.ant-spin-fullscreen {
    z-index: 10001!important;
}

.details-table-cell {
    word-break: break-word;
    overflow-wrap: break-word;
}